<template>
  <main id="app">
	<section class="showcase">
			<div class="video-container">
				<!-- <video src="https://traversymedia.com/downloads/video.mov" autoplay muted loop></video> -->
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/GUkd-Pxe1z4?autoplay=1&mute=1&loop=1"></iframe>
			</div>
			<div class="content">
				<h1>SOP HORAS</h1>
				<h3>Sistem Optimalisasi Pembayaran Honor Mitra Statistik</h3>
				<Button @click="login" style="background-color: brown;color: black;" label="Login" class="btn"/>
			</div>
		</section>

		<section id="about">
			<h1>Tentang</h1>
			<p>
				Aplikasi yang digunakan oleh BPS Kabupaten Sukamara dalam manajemen teknis dan adminitrasi terkait SPK.
			</p>

			<h2>Kunjungi kami</h2>

			<div class="social">
			<a href="https://twitter.com/traversymedia" target="_blank"><i class="pi pi-instagram"></i></a>
				<a href="https://facebook.com/traversymedia" target="_blank"><i class="pi pi-facebook"></i></a>
				<a href="https://github.com/bradtraversy" target="_blank"><i class="pi pi-globe"></i></a>
			</div>
		</section>
  </main>
</template>

<script>
import Button from 'primevue/button';
export default {
  components: {
    Button,
  },
  data() {
    return {
      mitraColumns: null,
      mitraData: null,
      mitraDialog: false,
      mitraBaru: true,
    };
  },
  async created() {},
  async mounted() {},
  methods: {
	login(){
		this.$router.push('/login')
	}
  },
};
</script>


<!-- Use preprocessors via the lang attribute! e.g. <style lang="scss"> -->
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One:wght@300;400&display=swap');

:root {
	--primary-color: #3a4052;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	font-family: 'Russo One', sans-serif;
	line-height: 1.5;
}

a {
	text-decoration: none;
	color: var(--primary-color);
}

h1 {
	font-weight: 300;
	font-size: 60px;
	line-height: 1.2;
	margin-bottom: 15px;
}

.showcase {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;
	padding: 0 20px;
}

.video-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: var(--secondary-color) url('https://traversymedia.com/downloads/cover.jpg') no-repeat center
		center/cover;
}

.video-container video {
	min-width: 100%;
	min-height: 100%;
  position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	object-fit: cover;
}

.video-container:after {
	content: '';
	z-index: 1;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	position: absolute;
}

.content {
	z-index: 2;
}

.btn {
	display: inline-block;
	padding: 10px 30px;
	background: "black";
	color: #fff;
	border-radius: 5px;
	border: solid #fff 1px;
	margin-top: 25px;
	opacity: 0.7;
}

.btn:hover {
	transform: scale(0.98);
}

#about {
	padding: 40px;
	text-align: center;
  background-color: #fff;
}

#about p {
	font-size: 1.2rem;
	max-width: 600px;
	margin: auto;
}

#about h2 {
	margin: 30px 0;
	color: rgb(142, 11, 11);
}

.social a {
	margin: 0 5px;
	color: #000;
}

</style>