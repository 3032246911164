import axios from "axios";
const API_URL = "https://horassop.gaweyan.com/api";
class AuthService {
  login(user) {
    console.log(user);
    return axios
      .post(API_URL + "/login", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch(function (error) {
        // console.log(error.toJSON());
        return error;
      });
  }
  logout() {
    localStorage.removeItem("user");
  }
  register(user) {
    return axios.post(API_URL + "/users", {
      name: user.name,
      email: user.email,
      password: user.password,
    });
  }
}
export default new AuthService();
