<template>
  <!-- start dialog untuk daftar mitra suatu kegiatan (Pekerjaan Mitra dialog) -->
  <Dialog
    :paginator="true"
    :rows="5"
    v-model:visible="displayMitraDialog"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '80vw' }"
    :maximizable="true"
    :modal="true"
  >
    <template #header>
      <Toolbar class="mb-4">
        <template #start>
          <Button
            icon="pi pi-plus"
            label="Tambah Mitra"
            style="background-color: black; color: red; float: left"
            class="mr-2"
            @click="openPencarianMitraDialog"
          ></Button>
          <Button
            label="Delete"
            icon="pi pi-trash"
            class="p-button-danger mr-2"
            @click="confirmDeleteSelectedPPekerjaanMitra"
            :disabled="
              !selectedPekerjaanMitra || !selectedPekerjaanMitra.length
            "
          />
          <FileUpload
            mode="basic"
            accept="image/*"
            :maxFileSize="1000000"
            label="Import"
            chooseLabel="Import"
            class="mr-2 inline-block"
          />
          <Button
            label="Export"
            icon="pi pi-download"
            class="p-button-help mr-2"
            @click="exportCSVPekerjaanMitra($event)"
          />
        </template>

        <template #end>
          <InputText
            v-model="filterPekerjaanMitra['global'].value"
            placeholder="Cari mitra"
            style="text-align: right; float: right; position: relative"
          />
        </template>
      </Toolbar>
    </template>
    <DataTable
      :value="pekerjaanMitraData"
      v-model:filters="filterPekerjaanMitra"
      dataKey="id_pekerjaan"
      filterDisplay="menu"
      :globalFilterFields="['nama_mitra', 'nama_kegiatan', 'satuan_pekerjaan']"
      v-model:editingRows="editingRowsPekerjaanMitra"
      @row-edit-save="onRowEditSavePekerjaanMitra"
      responsiveLayout="scroll"
      editMode="row"
      v-model:selection="selectedPekerjaanMitra"
      ref="dt"
    >
      <template #empty> Belum ada mitra untuk kegiatan ini </template>
      <template #loading> Loading data mitra untuk kegiatan . . . </template>
      <Column
        selectionMode="multiple"
        style="width: 3rem"
        :exportable="false"
      ></Column>
      <Column
        field="nama_mitra"
        header="Nama Mitra"
        footer="Nama Mitra"
        style="min-width: 200px"
        frozen
      ></Column>
      <Column
        field="nama_kegiatan"
        header="Kegiatan"
        footer="Kegiatan"
        style="min-width: 200px"
      ></Column>
      <Column
        field="tanggal_mulai_pekerjaan"
        header="Tanggal Mulai"
        footer="Tanggal Mulai"
        style="min-width: 200px"
      >
        <template #editor="{ data, field }">
          <Calendar
            v-model="data[field]"
            dateFormat="yy/mm/dd"
            :showTime="false"
          />
        </template>
      </Column>
      <Column
        field="tanggal_selesai_pekerjaan"
        header="Tanggal Selesai Pekerjaan"
        footer="Tanggal Selesai Pekerjaan"
        style="min-width: 200px"
      >
        <template #editor="{ data, field }">
          <Calendar
            v-model="data[field]"
            dateFormat="yy/mm/dd"
            :showTime="false"
          />
        </template>
      </Column>
      <Column
        field="satuan_pekerjaan"
        header="Satuan Pekerjaan"
        footer="Satuan Pekerjaan"
        :style="{ width: '200px' }"
      >
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>
      <Column
        field="jumlah_pekerjaan"
        header="Jumlah Pekerjaan"
        footer="Jumlah Pekerjaan"
        :style="{ width: '200px' }"
      >
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>
      <Column
        field="biaya_per_satuan_pekerjaan"
        header="Biaya Per Satuan Pekerjaan"
        footer="Biaya Per Satuan Pekerjaan"
        :style="{ width: '200px' }"
      >
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>
      <Column header="Biaya Pekerjaan">
        <template #body="slotProps">
          <div>
            {{
              slotProps.data.biaya_per_satuan_pekerjaan *
              slotProps.data.jumlah_pekerjaan
            }}
          </div>
        </template>
      </Column>
      <Column
        field="biaya_transportasi_pekerjaan"
        header="Biaya Transportasi Pekerjaan"
        footer="Biaya Transportasi Pekerjaan"
        :style="{ width: '200px' }"
      >
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>
      <Column
        field="biaya_harian_pekerjaan"
        header="Uang Harian Pekerjaan"
        footer="Uang Harian Pekerjaan"
        :style="{ width: '200px' }"
      >
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>
      <Column
        field="biaya_penginapan_pekerjaan"
        header="Biaya Penginapan Pekerjaan"
        footer="Biaya Penginapan Pekerjaan"
        :style="{ width: '200px' }"
      >
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>
      <Column
        :rowEditor="true"
        style="width: 10%; min-width: 8rem"
        bodyStyle="text-align:center"
      >
        <template #header>
          <Button type="button" class="p-button-text" style="color: black"
            >Edit Pekerjaan</Button
          >
        </template>
      </Column>
      <Column :style="{ width: '200px' }" bodyStyle="text-align: center">
        <template #header>
          <Button type="button" class="p-button-text" style="color: black"
            >Pengajuan SPK</Button
          >
        </template>
        <template #body="slotProps">
          <div v-if="slotProps.data.locked_pekerjaan == null">
            <Button
              type="button"
              icon="pi pi-send"
              class="p-button-info"
              label="Kirim"
              style="margin-right: 0.5em"
              @click="sentPengajuanSPK(slotProps.data)"
            ></Button>
          </div>
          <div v-else>
            <Button
              type="button"
              icon="pi pi-trash"
              class="p-button-danger"
              label="Batalkan"
              style="margin: 5px"
              @click="sentPembatalanSPK(slotProps.data)"
            ></Button>
          </div>
        </template>
      </Column>
    </DataTable>
  </Dialog>
  <!-- end -->

  <!-- start dialog untuk pencarian daftar mitra untuk didaftarkan suatu kegiatan -->
  <Dialog
    v-model:visible="displayPencarianMitraDialog"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '80vw' }"
    :modal="true"
  >
    <template #header>
      <h3>Pencarian Mitra untuk kegiatan : {{ kegiatan.nama_kegiatan }}</h3>
      <div class="flex justify-content-between">
        <!-- <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilterPencarianMitra()"/> -->
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            v-model="filterPencarianMitra['global'].value"
            placeholder="Keyword Search"
          />
        </span>
      </div>
    </template>
    <template #empty> Belum ada mitra dengan spesifikasi tersebut </template>
    <template #loading> Pencarian data mitra . . . </template>
    <DataTable
      :value="pencarianMitraData"
      v-model:filters="filterPencarianMitra"
      dataKey="nik_mitra"
      filterDisplay="menu"
      :globalFilterFields="['nama_mitra', 'nik_mitra', 'alamat_mitra']"
      :paginator="true"
      :rows="10"
    >
      <Column
        field="nama_mitra"
        header="Nama Mitra"
        footer="Nama Mitra"
        :style="{ width: '200px' }"
        frozen
      ></Column>
      <!-- <Column field="nama_mitra" header="Nama mitra" style="min-width:12rem">
          <template #body="{data}">
              {{data.nama_mitra}}
          </template>
      </Column> -->
      <Column
        field="nik_mitra"
        header="ID"
        footer="ID"
        :style="{ width: '100px' }"
      ></Column>
      <Column
        field="alamat_mitra"
        header="Alamat"
        footer="Alamat"
        :style="{ width: '200px' }"
      ></Column>
      <Column
        field="nomor_handphone_mitra"
        header="Handphone"
        footer="Handphone"
        :style="{ width: '200px' }"
      ></Column>
      <Column
        field="jenis_kelamin_mitra"
        header="Jenis Kelamin"
        footer="Jenis Kelamin"
        :style="{ width: '200px' }"
      ></Column>
      <Column
        field="alamat_email_mitra"
        header="Email"
        footer="Email"
        :style="{ width: '200px' }"
      ></Column>
      <Column :style="{ width: '200px' }" bodyStyle="text-align: center">
        <template #header>
          <Button type="button" class="p-button-text" style="color: black"
            >Action</Button
          >
        </template>
        <template #body="slotProps">
          <Button
            type="button"
            icon="pi pi-plus"
            class="p-button-info"
            style="margin-right: 0.5em"
            @click="submitDataPekerjaanMitraBaru(slotProps.data)"
          ></Button>
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="displayPencarianMitraDialog = false"
        style="color: red"
      />
    </template>
  </Dialog>
  <!-- end -->

  <!-- dialog delete -->
  <ConfirmDialog></ConfirmDialog>
  <!-- datatable kegiatan -->
  <div style="margin-top: 20px">
    <!-- datatable kegiatan -->
    <DataTable
      :value="kegiatanData"
      v-model:filters="filterPencarianKegiatan"
      dataKey="id_kegiatan"
      filterDisplay="menu"
      :globalFilterFields="[
        'nama_kegiatan',
        'penanggung_jawab_kegiatan',
        'id_kegiatan',
      ]"
      style="margin-top: 20px"
    >
      <template #header>
        <div style="text-align: left">
          <!-- <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilterPencarianMitra()"/> -->
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filterPencarianKegiatan['global'].value"
              placeholder="Cari kegiatan"
            />
          </span>

          <Button
            @click="openKegiatanBaruDialog"
            type="button"
            class="my-2 float-right"
            label="Tambah Kegiatan"
            icon="pi pi-plus"
            style="
              background-color: black;
              color: red;
              border: 5px solid white;
              border-radius: 6px;
              float: right;
              position: relative;
              z-index: 99;
              margin-right: 5px;
            "
          ></Button>
        </div>
      </template>
      <template #empty> Belum ada kegiatan </template>
      <template #loading> Loading data kegiatan ... </template>

      <Column
        field="nama_kegiatan"
        header="Nama"
        footer="Nama"
        :style="{ width: '350px' }"
        frozen
      ></Column>
      <Column
        field="id_kegiatan"
        header="ID"
        footer="ID"
        :style="{ width: '100px' }"
      ></Column>
      <Column
        field="penanggung_jawab_kegiatan"
        header="Penanggung Jawab Kegiatan (Fungsi/Satker)"
        footer="Penanggung Jawab Kegiatan (Fungsi/Satker)"
        :style="{ width: '350x' }"
      ></Column>
      <Column
        field="tanggal_mulai_kegiatan"
        header="Tanggal Mulai Kegiatan"
        footer="Tanggal Mulai Kegiatan"
        :style="{ width: '350x' }"
      ></Column>
      <Column
        field="tanggal_selesai_kegiatan"
        header="Tangal Selesai Kegiatan"
        footer="Tanggal Selesai Kegiatan"
        :style="{ width: '350x' }"
      ></Column>
      <Column
        field="beban_anggaran_kegiatan"
        header="Mata Anggaran Kegiatan"
        footer="Mata Anggaran Kegiatan"
        :style="{ width: '350x' }"
      ></Column>
      <Column :style="{ width: '500px' }" bodyStyle="text-align: left">
        <template #header>
          <Button type="button" class="p-button-text" style="color: black"
            >Action</Button
          >
        </template>
        <template #body="slotProps">
          <div v-if="user.id == slotProps.data.created_by || showKegiatanOther">
            <Button
              type="button"
              label="Mitra"
              icon="pi pi-users"
              class="p-button-success"
              style="margin: 0.5em"
              @click="openMitraDialog(slotProps.data)"
            ></Button>
            <Button
              type="button"
              label="Edit"
              icon="pi pi-pencil"
              class="p-button-warning"
              style="margin: 0.5em"
              @click="openKegiatanPemutakhiranDialog(slotProps.data)"
            ></Button>
            <Button
              type="button"
              label="Hapus"
              icon="pi pi-trash"
              class="p-button-danger"
              style="margin: 0.5em"
              @click="konfirmasiHapusKegiatan(slotProps.data)"
            ></Button>
          </div>
          <div v-else>Locked</div>
        </template>
      </Column>
    </DataTable>
  </div>
  <!-- dialog pendaftaran kegiatan -->
  <Dialog
    v-model:visible="kegiatanDialog"
    :style="{ width: '450px' }"
    :header="
      kegiatanBaru ? 'Pendaftaran Kegiatan Baru' : 'Pemutakhiran Data Kegiatan'
    "
    :modal="true"
    class="p-fluid"
  >
    <div class="field" v-if="!kegiatanBaru">
      <label for="id_kegiatan">ID Kegiatan</label>
      <InputText
        id="id_kegiatan"
        v-model="kegiatan.id_kegiatan"
        required="true"
        readonly
        disabled
      />
    </div>
    <div class="field">
      <label for="nama_kegiatan">Nama</label>
      <InputText
        id="nama_kegiatan"
        v-model="kegiatan.nama_kegiatan"
        required="true"
        placeholder="Contoh: Susenas Maret 2022"
      />
    </div>
    <div class="field">
      <label for="penanggung_jawab_kegiatan">Penanggung Jawab Kegiatan</label>
      <InputText
        id="penanggung_jawab_kegiatan"
        v-model="kegiatan.penanggung_jawab_kegiatan"
        required="true"
        placeholder="Contoh: Fungsi Statistik Sosial"
      />
    </div>
    <div class="field">
      <label for="satuan_kegiatan">Satuan Kegiatan</label>
      <InputText
        id="satuan_kegiatan"
        v-model="kegiatan.satuan_kegiatan"
        required="true"
        placeholder="Contoh: Dokumen, Geotagging Laporan"
      />
    </div>
    <div class="field">
      <label for="beban_anggaran_kegiatan">Mata Anggaran Kegiatan (MAK)</label>
      <InputText
        id="beban_anggaran_kegiatan"
        v-model="kegiatan.beban_anggaran_kegiatan"
        required="true"
        placeholder="Contoh: 2903.BMA.004.052.521213"
      />
    </div>
    <div class="field">
      <label for="klasifikasi_kegiatan">Jenis/Klasifikasi Kegiatan Utama</label>
      <InputText
        id="klasifikasi_kegiatan"
        v-model="kegiatan.klasifikasi_kegiatan"
        required="true"
        placeholder="Contoh: Pencacahan"
      />
    </div>
    <div class="field">
      <label for="tanggal_mulai_kegiatan">Tanggal Mulai Kegiatan</label>
      <Calendar
        :utc="true"
        id="tanggal_mulai_kegiatan"
        v-model="kegiatan.tanggal_mulai_kegiatan"
        dateFormat="yy/mm/dd"
        :showTime="false"
      />
    </div>

    <div class="field">
      <label for="tanggal_selesai_kegiatan">Tanggal Selesai Kegiatan</label>
      <Calendar
        :utc="true"
        id="tanggal_selesai_kegiatan"
        v-model="kegiatan.tanggal_selesai_kegiatan"
        dateFormat="yy/mm/dd"
        :showTime="false"
      />
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="kegiatanDialog = false"
        style="color: red"
      />
      <Button
        v-if="kegiatanBaru"
        label="Submit Kegiatan Baru"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitDataKegiatanBaru"
      />
      <Button
        v-else
        label="Submit Pemutakhiran Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitPemutahiranDataKegiatan"
      />
    </template>
  </Dialog>
</template>
<script>
import moment from "moment";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import ConfirmDialog from "primevue/confirmdialog";
import RadioButton from "primevue/radiobutton";
import Toolbar from "primevue/toolbar";
import FileUpload from "primevue/fileupload";
import Calendar from "primevue/calendar";
import KegiatanService from "@/services/kegiatan.service";
import MitraService from "@/services/mitra.service";
import PekerjaanMitraService from "@/services/pekerjaan-mitra.service";
import UserService from "@/services/user.service";

import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    ConfirmDialog,
    InputText,
    RadioButton,
    Toolbar,
    FileUpload,
    Calendar,
    KegiatanService,
    MitraService,
    PekerjaanMitraService,
    UserService,
  },
  data() {
    return {
      kegiatanColumns: null,
      kegiatanDialog: false,
      kegiatanBaru: true,
      kegiatanData: [],
      editingRowsPekerjaanMitra: [],
      selectedPekerjaanMitra: [],
      pencarianMitraData: null,
      pekerjaanMitraData: null,
      user: {},
      kegiatan: {
        nama_kegiatan: null,
        id_kegiatan: null,
        penanggung_jawab_kegiatan: null,
        tanggal_mulai_kegiatan: Date.now(),
        tanggal_selesai_kegiatan: Date.now(),
        satuan_kegiatan: null,
        beban_anggaran_kegiatan: null,
        created_by: null,
        updated_by: null,
        klasifikasi_kegiatan: null,
      },
      mitra: {
        nama_mitra: null,
        alamat_mitra: null,
        nomor_handphone_mitra: null,
        alamat_email_mitra: null,
        nik_mitra: null,
      },
      pekerjaan_mitra: {
        id_pekerjaan: null,
        id_mitra: null,
        nama_mitra: null,
        id_kegiatan: null,
        nama_kegiatan: null,
        jumlah_pekerjaan: null,
        satuan_pekerjaan: null,
        biaya_per_satuan_pekerjaan: null,
        biaya_transportasi_pekerjaan: null,
        biaya_penginapan_pekerjaan: null,
        biaya_harian_pekerjaan: null,
        keterangan_pekerjaan: null,
        beban_anggaran_pekerjaan: null,
        created_by: null,
        updated_by: null,
        klasifikasi_pekerjaan: null,
        posisi_pekerjaan: null,
        tanggal_mulai_pekerjaan: Date.now(),
        tanggal_selesai_pekerjaan: Date.now(),
      },
      showKegiatanOther: false,
      profil: null,
      displayMitraDialog: false,
      displayPencarianMitraDialog: false,
      filterPencarianMitra: null,
      filterPencarianKegiatan: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_kegiatan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        penanggung_jawab_kegiatan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        id_kegiatan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      filterPekerjaanMitra: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_mitra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_kegiatan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        satuan_pekerjaan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
    };
  },
  async created() {
    this.user = await JSON.parse(localStorage.getItem("details"));
    // console.log(this.user);

    this.kegiatanData = [];
    await this.initKegiatan();

    this.pencarianMitraData = [];
    await this.initMitra();

    this.pekerjaanMitraData = [];

    await this.initFilterPencarianMitra();
    await this.initFilterPencarianKegiatan();
  },
  async mounted() {},
  methods: {
    async initKegiatan() {
      var profil_temp = await UserService.getUserDetail();
      this.profil = profil_temp.data;
      if (this.profil.role == "Super Admin") {
        this.showKegiatanOther = true;
      }

      var response_temp = await KegiatanService.getKegiatan();
      this.kegiatanData = response_temp.data;
      // console.log(this.kegiatanData)
    },
    async initMitra() {
      var response_temp = await MitraService.getMitraBebasByIdKegiatan(
        this.kegiatan.id_kegiatan
      );
      this.pencarianMitraData = response_temp.data;
      // console.log(this.kegiatanData)
    },
    async initPekerjaanMitra(id_kegiatan) {
      var response_temp =
        await PekerjaanMitraService.getPekerjaanMitraByIdKegiatan(id_kegiatan);
      if (response_temp.status != null && response_temp.status == "success") {
        this.pekerjaanMitraData = response_temp.data;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil loading data mitra",
          life: 5000,
        });
      } else {
        this.pekerjaanMitraData = [];
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 7000,
        });
      }
    },
    konfirmasiHapusKegiatan(data) {
      this.$confirm.require({
        message: "Apa yakin ingin menghapus data kegiatan ini?",
        header: "Konfirmas Penghapusan",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus",
        rejectLabel: "Cancel",
        accept: async () => {
          //callback to execute when user confirms the action
          var response_data = await KegiatanService.deleteKegiatanById(
            data.id_kegiatan
          );
          if (
            response_data.status != null &&
            response_data.status == "success"
          ) {
            await this.initKegiatan();
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus data",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_data,
              life: 7000,
            });
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi untuk menghapus mitra dari kegiatan
    async konfirmasiHapusMitra(id_kegiatan) {
      this.$confirm.require({
        message: "Apa yakin ingin menghapus data kegiatan ini?",
        header: "Konfirmas Penghapusan",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus",
        rejectLabel: "Cancel",
        accept: async () => {
          //callback to execute when user confirms the action
          var response_data = await KegiatanService.deleteKegiatanById(
            id_kegiatan
          );
          if (
            response_data.status != null &&
            response_data.status == "success"
          ) {
            await this.initKegiatan();
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menambah kegiatan",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_data,
              life: 7000,
            });
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi membuka dialog untuk pendaftaran kegiatan baru
    openKegiatanBaruDialog() {
      this.kegiatanBaru = true;
      this.kegiatan = {
        nama_kegiatan: null,
        penanggung_jawab_kegiatan: null,
        satuan_kegiatan: null,
        tanggal_mulai_kegiatan: null,
        tanggal_selesai_kegiatan: null,
        klasifikasi_kegiatan: null,
        beban_anggaran_kegiatan: null,
      };
      this.kegiatanDialog = true;
    },
    // fungsi membuka dialog untuk pemutakhiran data kegiatan
    openKegiatanPemutakhiranDialog(data) {
      this.kegiatan = data;
      this.kegiatanBaru = false;
      this.kegiatanDialog = true;
    },
    // fungsi submit data kegiatan baru
    async submitDataKegiatanBaru() {
      var kegiatan_temp = this.kegiatan;
      kegiatan_temp.tanggal_mulai_kegiatan = moment(
        kegiatan_temp.tanggal_mulai_kegiatan
      ).format("YYYY/MM/DD");
      kegiatan_temp.tanggal_selesai_kegiatan = moment(
        kegiatan_temp.tanggal_selesai_kegiatan
      ).format("YYYY/MM/DD");
      console.log(kegiatan_temp);
      var response_temp = await KegiatanService.submitKegiatanBaru(
        kegiatan_temp
      );
      if (
        response_temp.data.status != null &&
        response_temp.data.status == "success"
      ) {
        await this.initKegiatan();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil menambah kegiatan",
          life: 5000,
        });
        this.kegiatanDialog = false;
        this.kegiatan = {
          nama_kegiatan: null,
          penanggung_jawab_kegiatan: null,
          satuan_kegiatan: null,
          tanggal_mulai_kegiatan: null,
          tanggal_selesai_kegiatan: null,
          klasifikasi_kegiatan: null,
          beban_anggaran_kegiatan: null,
        };
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 7000,
        });
      }
      // console.log(kegiatan_temp);
    },
    // fungsi submit pemutakhiran data kegiatan
    async submitPemutahiranDataKegiatan() {
      var kegiatan_temp = this.kegiatan;
      kegiatan_temp.tanggal_mulai_kegiatan = moment(kegiatan_temp.tanggal_mulai_kegiatan).format('YYYY/MM/DD');
      kegiatan_temp.tanggal_selesaimulai_kegiatan = moment(kegiatan_temp.tanggal_selesai_kegiatan).format('YYYY/MM/DD');
      console.log(kegiatan_temp);
      var response_temp = await KegiatanService.submitPemutakhiranKegiatan(
        kegiatan_temp
      );
      if (
        response_temp.data.status != null &&
        response_temp.data.status == "success"
      ) {
        await this.initKegiatan();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan data kegiatan",
          life: 5000,
        });
        this.kegiatanDialog = false;
        this.kegiatan = {
          nama_kegiatan: null,
          penanggung_jawab_kegiatan: null,
          satuan_kegiatan: null,
          tanggal_mulai_kegiatan: null,
          tanggal_selesai_kegiatan: null,
          klasifikasi_kegiatan: null,
          beban_anggaran_kegiatan: null,
        };
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 7000,
        });
      }
    },
    // fungsi submit data pekerjaan mitra baru
    async submitDataPekerjaanMitraBaru(data) {
      this.mitra = data;
      var kegiatan_temp = this.kegiatan;
      kegiatan_temp.tanggal_mulai_kegiatan = moment(kegiatan_temp.tanggal_mulai_kegiatan).format('YYYY/MM/DD');
      kegiatan_temp.tanggal_selesai_kegiatan = moment(kegiatan_temp.tanggal_selesai_kegiatan).format('YYYY/MM/DD');
      console.log(this.mitra);
      var response_data = await PekerjaanMitraService.submitPekerjaanMitraBaru(
        this.mitra,
        kegiatan_temp,
      );
      if (response_data.status != null && response_data.status == "success") {
        await this.initMitra();
        await this.initPekerjaanMitra(this.kegiatan.id_kegiatan);
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil menambah mitra dalam kegiatan",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      // console.log(kegiatan_temp);
    },
    // fungsi submit pemutakhiran data pekerjaan mitra
    async submitPemutahiranDataPekerjaanMitra() {
      var kegiatan_temp = this.kegiatan;
      kegiatan_temp.tanggal_mulai_kegiatan = moment(kegiatan_temp.tanggal_mulai_kegiatan).format('YYYY/MM/DD');
      kegiatan_temp.tanggal_selesa_kegiatan = moment(kegiatan_temp.tanggal_selesa_kegiatan).format('YYYY/MM/DD');
      console.log(kegiatan_temp);
      var response_temp = await KegiatanService.submitPemutakhiranKegiatan(
        kegiatan_temp
      );
      if (
        response_temp.data.status != null &&
        response_temp.data.status == "success"
      ) {
        await this.initKegiatan();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan data kegiatan",
          life: 5000,
        });
        this.kegiatanDialog = false;
        this.kegiatan = {
          nama_kegiatan: null,
          penanggung_jawab_kegiatan: null,
          satuan_kegiatan: null,
          tanggal_mulai_kegiatan: null,
          tanggal_selesai_kegiatan: null,
          klasifikasi_kegiatan: null,
          beban_anggaran_kegiatan: null,
        };
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 7000,
        });
      }
    },
    // fungsi untuk membuka dialog mitra sekaligus loading data mitra
    async openMitraDialog(data) {
      this.kegiatan = data;
      var response_temp =
        await PekerjaanMitraService.getPekerjaanMitraByIdKegiatan(
          data.id_kegiatan
        );
      if (response_temp.status != null && response_temp.status == "success") {
        this.pekerjaanMitraData = response_temp.data;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil loading data mitra",
          life: 5000,
        });
      } else {
        this.pekerjaanMitraData = [];
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 7000,
        });
      }
      this.displayMitraDialog = true;
      console.log("Dialog Pekerjaan Mitra");
    },
    // fungsi untuk menutup dialog mitra
    closeMitraDialog() {
      this.displayMitraDialog = false;
    },
    //fungsi untuk membuka dialog pencarian mitra
    async openPencarianMitraDialog() {
      await this.initMitra();
      this.displayPencarianMitraDialog = true;
    },
    //fungsi untuk menutup dialog pencarian mitra
    closePencarianMitraDialog() {
      this.displayPencarianMitraDialog = false;
    },
    // fungsi untuk pencarian kegiatan
    clearFilterPencarianKegiatan() {
      this.initFilterPencarianKegiatan();
    },
    async initFilterPencarianKegiatan() {
      this.filterPencariankegiatan = await {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_kegiatan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        penanggung_jawab_kegiatan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        id_kegiatan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    // fungsi untuk filter di pencarian mitra
    clearFilterPencarianMitra() {
      this.initFilterPencarianMitra();
    },
    async initFilterPencarianMitra() {
      this.filterPencarianMitra = await {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_mitra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nik_mitra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        alamat_mitra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    // fungsi untuk filter pencarian di tabel pekerjaan mitra
    clearFilterPekerjaanMitra() {
      this.initFilterPekerjaanMitra();
    },
    async initFilterPekerjaanMitra() {
      this.filterPekerjaanMitra = await {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_mitra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_kegiatan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        satuan_pekerjaan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    //fungsi untuk editing-saving pekerjaan mitra per baris
    async onRowEditSavePekerjaanMitra(event) {
      let { newData, index } = event;
      // this.mitra = data;
      // console.log(this.mitra);
      newData.tanggal_mulai_pekerjaan = moment(newData.tanggal_mulai_pekerjaan).format('YYYY/MM/DD');
      newData.tanggal_selesai_pekerjaan = moment(newData.tanggal_selesai_pekerjaan).format('YYYY/MM/DD');
      var response_data =
        await PekerjaanMitraService.submitPemutakhiranPekerjaanMitra(newData);
      if (response_data.status != null && response_data.status == "success") {
        await this.initMitra();
        await this.initPekerjaanMitra(this.kegiatan.id_kegiatan);
        this.pekerjaanMitraData[index] = newData;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan data pekerjaan mitra",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
    },
    //fungsi pengajuan SPK
    async sentPengajuanSPK(data) {
      var response_data = await PekerjaanMitraService.pengajuanSPKMitra(data);
      if (response_data.status != null && response_data.status == "success") {
        await this.initPekerjaanMitra(this.kegiatan.id_kegiatan);
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil mengajukan SPK pekerjaan mitra",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
    },
    // fungsi pembatalan SPK untuk kegiatan
    async sentPembatalanSPK(data) {
      var response_data = await PekerjaanMitraService.pembatalanSPKMitra(data);
      if (response_data.status != null && response_data.status == "success") {
        await this.initPekerjaanMitra(this.kegiatan.id_kegiatan);
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil membatalkan SPK pekerjaan mitra",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
    },
    //fungsi export CSV untuk Pekerjaan Mitra
    exportCSVPekerjaanMitra() {
      this.$refs.dt.exportCSV();
    },
    //fungsi untuk hapus selected
    confirmDeleteSelectedPPekerjaanMitra() {
      this.$confirm.require({
        message: "Apa yakin ingin mitra dari kegiatan " + this.kegiatan.nama_kegiatan +"?",
        header: "Konfirmasi Penghapusan",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus",
        rejectLabel: "Cancel",
        accept: async () => {
          var selected_ids = [];
          this.selectedPekerjaanMitra.forEach((element) => {
              console.log(element);
              selected_ids.push(element.id_pekerjaan);
          });
          console.log(selected_ids);
          //callback to execute when user confirms the action
          var response_data = await PekerjaanMitraService.deletePekerjaanMitraByIds(
            this.kegiatan.id_kegiatan, selected_ids
          );
          if (
            response_data.status != null &&
            response_data.status == "success"
          ) {
            await this.initPekerjaanMitra(this.kegiatan.id_kegiatan);
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus data",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_data,
              life: 7000,
            });
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.blackButton {
  background-color: #f5fbff !important;
}

body {
  background: url("~@/assets/bg-login.png") no-repeat center center fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
}
</style>
