<template>
  <Toast />
  <Loading
    v-model:active="isloadingglobal"
    :can-cancel="false"
    :is-full-page="true"
    color="#D22D45"
    loader="dots"
  />
  <div style="margin:10px;">
    <MenuBar />
  </div>
  
  <section style="margin:10px;">
    <router-view></router-view>
  </section>
  <div class="layout-footer" style="background-color: azure; margin: 20px">
    <div class="layout-footer-left">
      <span>Athan@</span>
      <a href="https://www.bps.go.id">Badan Pusat Statistik</a>
    </div>

    <div class="layout-footer-right">
      <a href="https://github.com/primefaces/primevue" class="mr-3">
        <i class="pi pi-github"></i>
      </a>
      <a href="https://twitter.com/primevue" class="mr-3">
        <i class="pi pi-twitter"></i>
      </a>
      <a href="https://discord.com/invite/gzKFYnpmCY">
        <i class="pi pi-discord"></i>
      </a>
    </div>
  </div>
</template>
<script>
import MenuBar from "../components/MenuBar.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Toast from "primevue/toast";
import UserService from "@/services/user.service";

export default {
  data() {
    return {
      isLoading: this.isloadingglobal,
      fullPage: true,
    };
  },
  components: {
    MenuBar,
    Loading,
    Toast,
    UserService,
  },
  async created() {
    var user = await UserService.getUserDetail();
    if (user == null || user == 0) {
      this.$router.push("/login");
    }
  },
};
</script>
<style lang="scss">
body {
  background: url("~@/assets/bg-login.png") no-repeat center center fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
}
</style>
