<template>
  <Menubar :model="items" style="background-color: white">
    <template #start>
      <img alt="logo" src="@/assets/logo.png" height="40" class="mr-2" />
    </template>
    <template #item="{ item }">
      <router-link
        :to="item.to"
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <Button
          class="p-button-text"
          style="color: black"
          :href="href"
          @click="navigate"
          :class="{
            'active-link': isActive,
            'active-link-exact': isExactActive,
          }"
          >{{ item.label }}</Button
        >
      </router-link>
    </template>
    <template #end>
      <!-- <InputText placeholder="Search" type="text" /> -->
      <Button
        label="Profil"
        class="p-button-info p-button-text"
        @click="changeRoute('/manajemen-user')"
      />

      <Button
        label="Logout"
        class="p-button-danger p-button-text"
        @click="logout"
      />
    </template>
  </Menubar>
</template>

<script>
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import AuthService from "../services/auth.service";

export default {
  components: {
    Menubar,
    Button,
    AuthService,
  },
  data() {
    return {
      items: [
        {
          label: "Home",
          icon: "pi pi-fw pi-file",
          to: "home",
        },
        {
          label: "Mitra",
          icon: "pi pi-fw pi-pencil",
          to: "manajemen-mitra",
        },
        {
          label: "Kegiatan",
          icon: "pi pi-fw pi-user",
          to: "manajemen-kegiatan",
        },
        {
          label: "Perjanjian Kerja",
          icon: "pi pi-fw pi-calendar",
          to: "manajemen-perjanjian-kerja",
        },
      ],
    };
  },
  methods: {
    async logout() {
      await AuthService.logout();
      this.$router.push("/login");
    },
    changeRoute(route) {
      // `route` is either a string or object
      this.$router.push(route);
    },
  },
};
</script>
