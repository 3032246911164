<template>
  <div id="app">
    <h2
      class="my-2"
      style="
        color: white;
        font-weight: bold;
        float: left;
        text-align: left;
        -webkit-text-stroke: 1px black;
      "
    >
      Rekap Mitra
    </h2>
    <button @click="beforeData">Bulan Sebelum</button>
    <button @click="currentData">Bulan Sekarang</button>
    <button @click="afterData">Bulan Sesudah</button>
  </div>
  <br />
  <div class="chart-container" style="margin: 1em;">
    <Chart
      id="primeChart"
      ref="primeChart"
      type="bar"
      :data="basicData"
      :options="basicOptions"
      width="700"
      height="280"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import Chart from "primevue/chart";
import PekerjaanMitraService from "@/services/pekerjaan-mitra.service";

export default {
  name: "Home",
  components: {
    Chart,
  },
  async mounted() {
    this.basicData = await this.currentData;
  },
  setup() {
    const primeChart = ref();

    var nama_mitra = [];
    var datasets = [];

    const currentData = async () => {
      const chart = primeChart.value.chart;

      var today = new Date();
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = mm == 12 ? today.getFullYear() - 1 : today.getFullYear();

      var response_current_month =
        await PekerjaanMitraService.getPekerjaanMitraRekapTahunBulan(yyyy, mm);

      var arr_pembayaran = [];
      var nama_mitra = await response_current_month.data.map(
        (row) => row.nama_mitra
      );

      console.log(response_current_month);
      await response_current_month.data.map((row) =>
        arr_pembayaran.push(parseFloat(row.bayaran_pekerjaan))
      );
      var datasets = [
        {
          label: "Mitra Lapangan Tahun " + yyyy + " Bulan " + mm,
          backgroundColor: "#c90a15",
          data: arr_pembayaran,
          borderColor: "black",
        },
      ];

      chart.data.labels = nama_mitra;
      chart.data.datasets = datasets;
      chart.update();
    };

    const beforeData = async () => {
      const chart = primeChart.value.chart;

      var today = new Date();
      var mm = String(today.getMonth()).padStart(2, "0"); //January is 0!
      var yyyy = mm == 12 ? today.getFullYear() - 1 : today.getFullYear();

      var response_current_month =
        await PekerjaanMitraService.getPekerjaanMitraRekapTahunBulan(yyyy, mm);

      var arr_pembayaran = [];
      var nama_mitra = await response_current_month.data.map(
        (row) => row.nama_mitra
      );

      console.log(response_current_month);
      await response_current_month.data.map((row) =>
        arr_pembayaran.push(parseFloat(row.bayaran_pekerjaan))
      );
      var datasets = [
        {
          label: "Mitra Lapangan Tahun " + yyyy + " Bulan " + mm,
          backgroundColor: "#c90a15",
          data: arr_pembayaran,
          borderColor: "black",
        },
      ];

      chart.data.labels = nama_mitra;
      chart.data.datasets = datasets;
      chart.update();
    };

    const afterData = async () => {
      const chart = primeChart.value.chart;

      var today = new Date();
      var mm = String(today.getMonth() + 2).padStart(2, "0"); //January is 0!
      var yyyy = mm == 12 ? today.getFullYear() - 1 : today.getFullYear();

      var response_current_month =
        await PekerjaanMitraService.getPekerjaanMitraRekapTahunBulan(yyyy, mm);

      var arr_pembayaran = [];
      var nama_mitra = await response_current_month.data.map(
        (row) => row.nama_mitra
      );

      console.log(response_current_month);
      await response_current_month.data.map((row) =>
        arr_pembayaran.push(parseFloat(row.bayaran_pekerjaan))
      );
      var datasets = [
        {
          label: "Mitra Lapangan Tahun " + yyyy + " Bulan " + mm,
          backgroundColor: "#c90a15",
          data: arr_pembayaran,
          borderColor: "black",
        },
      ];

      chart.data.labels = nama_mitra;
      chart.data.datasets = datasets;
      chart.update();
    };

    return {
      primeChart,
      beforeData,
      currentData,
      afterData,
      basicData: {
        labels: nama_mitra,
        datasets: datasets,
      },
      basicOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#fff",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#fff",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#fff",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
        responsive: true,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #041525;
  margin-top: 20px;
}

#primeChart {
  max-width: 80vw;
}

body {
  background: url("~@/assets/bg-login.png") no-repeat center center fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
}
</style>
