import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config"; // This imports the PrimeVue library.
import "primeicons/primeicons.css";
import ConfirmationService from "primevue/confirmationservice";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ToastService from "primevue/toastservice";

const app = createApp(App);
app.config.globalProperties.isloadingglobal = false;

app.use(store);
app.use(router);
app.use(PrimeVue, {
  ripple: true,
  locale: {
    dayNames: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
    dayNamesShort: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
    dayNamesMin: ["Mi", "Sn", "Sl", "Rb", "Km", "Jm", "Sb"],
    monthNames: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ],
    today: "Hari ini",
    dateFormat: "yy/mm/dd",
  },
});
app.use(ConfirmationService);
app.use(ToastService);
app.use(VueLoading);
app.mount("#app");
// createApp(App).use(store).use(router).mount("#app");
