<template>
  <TabView style="margin-top: 20px">
    <TabPanel>
      <template #header>
        <i class="pi pi-calendar"></i>
        <span>Profile</span>
      </template>
      <div style="text-align: left">
        <div class="field">
          <label for="id">ID</label><br />
          <InputText id="id" v-model="profil.id" required="true" disabled />
        </div>
        <div class="field">
          <label for="name">Nama</label><br />
          <InputText id="name" v-model="profil.name" required="true" />
        </div>
        <div class="field">
          <label for="email">Email</label><br />
          <InputText id="email" v-model="profil.email" required="true" />
        </div>
        <div class="field">
          <label for="password">Password</label><br />
          <InputText id="password" v-model="profil.password" required="true" />
        </div>
      </div>
    </TabPanel>
    <TabPanel v-if="showUsers">
      <template #header>
        <span>Daftar Pengguna</span>
        <i class="pi pi-user"></i>
      </template>
      <DataTable :value="users" :paginator="true" :rows="10">
        <template #header>
          <div style="text-align: left">
            <!-- <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilterPencarianMitra()"/> -->
            <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText
                    v-model="filterPencarianKegiatan['global'].value"
                    placeholder="Cari kegiatan"
                    />
                </span> -->

            <Button
              @click="openUserBaruDialog"
              type="button"
              class="my-2 float-right"
              label="Tambah User"
              icon="pi pi-plus"
              style="
                background-color: black;
                color: red;
                border: 5px solid white;
                border-radius: 6px;
                float: right;
                position: relative;
                z-index: 99;
                margin-right: 5px;
              "
            ></Button>
          </div>
        </template>
        <Column
          field="id"
          header="ID"
          footer="ID"
          :style="{ width: '200px' }"
          frozen
        ></Column>
        <Column
          field="name"
          header="Nama"
          footer="Nama"
          :style="{ width: '100px' }"
        ></Column>
        <Column
          field="email"
          header="Email"
          footer="Email"
          :style="{ width: '200px' }"
        ></Column>
        <Column :style="{ width: '200px' }" bodyStyle="text-align: center">
          <template #header>
            <Button type="button" class="p-button-text" style="color: black"
              >Action</Button
            >
          </template>
          <template #body="slotProps">
            <Button
              type="button"
              icon="pi pi-pencil"
              class="p-button-warning"
              style="margin-right: 0.5em"
              @click="openUserPemutakhirDialog(slotProps.data)"
            ></Button>
            <Button
              type="button"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="konfirmasiHapusUser(slotProps.data)"
            ></Button>
          </template>
        </Column>
      </DataTable>
    </TabPanel>
  </TabView>

  <!-- <Button type="button" label="Pencarian" icon="pi pi-search" style="margin:5px;background-color:black;color:red;border: 5px solid white;border-radius: 6px;"/> -->
  <ConfirmDialog style="color: black"></ConfirmDialog>

  <!-- dialog pendaftaran user -->
  <Dialog
    v-model:visible="userDialog"
    :style="{ width: '450px' }"
    :header="userBaru ? 'Pendaftaran Baru' : 'Pemutakhiran Data User'"
    :modal="true"
    class="p-fluid"
  >
    <div class="field" v-if="!userBaru">
      <label for="id">ID</label>
      <InputText id="id" v-model="user.id" required="true" disabled />
    </div>
    <div class="field">
      <label for="name">Nama</label>
      <InputText id="name" v-model="user.name" required="true" />
    </div>
    <div class="field">
      <label for="email">Email</label>
      <InputText id="email" v-model="user.email" required="true" />
    </div>
    <div class="field">
      <label for="password">Password</label>
      <InputText id="password" v-model="user.password" required="true" />
    </div>
    <div class="field">
      <label for="status_admin">Status Admin</label>
      <Dropdown
        id="status_admin"
        v-model="user.admin"
        :options="admin_choices"
        optionLabel="name"
        optionValue="value"
        placeholder="Status Admin"
      />
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="userDialog = false"
        style="color: red"
      />
      <Button
        v-if="userBaru"
        label="Submit User Baru"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitDataUserBaru"
      />
      <Button
        v-else
        label="Submit Pemutakhiran Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitPemutahiranDataUser"
      />
    </template>
  </Dialog>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import ConfirmDialog from "primevue/confirmdialog";
import RadioButton from "primevue/radiobutton";
import FileUpload from "primevue/fileupload";
import Calendar from "primevue/calendar";
import Toast from "primevue/toast";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import UserService from "@/services/user.service";
import Dropdown from "primevue/dropdown";

export default {
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    ConfirmDialog,
    InputText,
    RadioButton,
    FileUpload,
    UserService,
    Toast,
    Calendar,
    TabPanel,
    TabView,
    Dropdown,
  },
  data() {
    return {
      users: [],
      userDialog: false,
      userBaru: true,
      user: {
        id: null,
        name: null,
        email: null,
      },
      profil: {
        id: null,
        name: null,
        email: null,
        password: null,
      },
      user_file: null,
      showUsers: false,
      admin_choices: [
        { name: "Admin", value: 1 },
        { name: "Non Admin", value: 0 },
      ],
    };
  },
  async created() {},
  async mounted() {
    await this.initUsers();

    this.$toast.add({
      severity: "success",
      summary: "Sukses dung",
      detail: "Berhasil loading",
      life: 3000,
    });
    // setTimeout(() => {
    //   this.$toast.add({severity:'success', summary: 'Sukses dung', detail: "Berhasil loading", life: 3000});
    // },
    // 5000);
  },
  methods: {
    async initUsers() {
      var profil_temp = await UserService.getUserDetail();
      this.profil = profil_temp.data;
      if (this.profil.role == "Super Admin") {
        var data = await UserService.getUsers();
        var users_temp = data.data;
        var users_temp2 = [];
        this.users = [];

        users_temp.forEach((element) => {
          // console.log(element.roles.split(","));
          if (
            element.roles.split(",").includes(1) ||
            element.roles.split(",").includes("1")
          ) {
            // console.log(element);
            users_temp2.push({
              id: element.id,
              name: element.name,
              email: element.email,
              password: element.password,
              admin: 1,
            });
          } else {
            users_temp2.push({
              id: element.id,
              name: element.name,
              email: element.email,
              password: element.password,
              admin: 0,
            });
          }
        });
        this.users = users_temp2;
        this.showUsers = true;
      }
    },
    konfirmasiHapusUser(data) {
      this.$confirm.require({
        message: "Apa yakin ingin menghapus data user ini?",
        header: "Konfirmas Penghapusan",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          //callback to execute when user confirms the action
          var response_temp = await UserService.deleteUserById(data.id);
          if (
            response_temp.status != null &&
            response_temp.status == "success"
          ) {
            await this.initUsers();
            this.userDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus user",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi membuka dialog untuk pendaftaran user baru
    openUserBaruDialog() {
      this.userBaru = true;
      this.user = {
        id: null,
        name: null,
        email: null,
        password: null,
        admin: null,
      };
      this.userDialog = true;
    },
    // fungsi membuka dialog untuk pemutakhiran data user
    openUserPemutakhirDialog(data) {
      this.user = data;
      this.userBaru = false;
      this.userDialog = true;
    },
    // fungsi submit data user baru
    async submitDataUserBaru() {
      var user_temp = this.user;
      var response_temp = await UserService.submitUserBaru(user_temp);
      if (response_temp.status != null && response_temp.status == "success") {
        await this.initUsers();
        this.userDialog = false;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil menambahkan user",
          life: 5000,
        });
        this.user = {
          name: null,
          email: null,
          alamat_email_user: null,
          nomor_handphone_user: null,
          nik_user: null,
          jenis_kelamin_user: null,
          tanggal_lahir_user: null,
        };
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // fungsi submit pemutakhiran data user
    async submitPemutahiranDataUser() {
      var user_temp = this.user;
      console.log(user_temp);
      var response_temp = await UserService.submitPemutakhiranUser(user_temp);
      if (response_temp.status != null && response_temp.status == "success") {
        await this.initUsers();
        this.userDialog = false;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan user",
          life: 5000,
        });
        this.user = {
          id: null,
          name: null,
          email: null,
          password: null,
        };
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // fungsi upload template user
    async uploadTemplateUser(event) {
      const formData = new FormData();
      formData.append("user_file", event.files[0]);
      var response_temp = await UserService.submitUploadTemplateUser(formData);
      if (response_temp.status != null && response_temp.status == "success") {
        await this.initUsers();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil mengupload data user",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // fungsi upload template user
    async downloadTemplateUser() {
      var response_temp = await UserService.submitDownloadTemplateUser();
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.ms-excel",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "percobaan.xlsx");
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  background: url("~@/assets/bg-login.png") no-repeat center center fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
}
</style>
