<template>
  <!-- <Panel header="Manajemen Mitra" style="align-text:left;">
    Bagian pengaturan daftar mitra yang terdaftar di lingkungan BPS Kabupaten Sukamara.
    SIlahkan lakukan pemutakhiran data mitra terakhir untuk kebutuhan penugasan dan adminitrasi yang lebih baik.
  </Panel> -->
  <div style="margin: 20px">
    <h2
      class="my-2"
      style="
        color: white;
        font-weight: bold;
        float: left;
        text-align: left;
        -webkit-text-stroke: 1px black;
      "
    >
      Manajemen Mitra
    </h2>

    <Button
      @click="openMitraBaruDialog"
      type="button"
      class="my-2 float-right"
      label="Tambah Mitra"
      icon="pi pi-plus"
      style="
        background-color: black;
        color: red;
        border: 5px solid white;
        border-radius: 6px;
        float: right;
        position: relative;
        z-index: 99;
        margin-right: 5px;
      "
    ></Button>

    <Button
      type="button"
      class="my-2 float-right"
      label="Download Template Mitra"
      icon="pi pi-download"
      style="
        background-color: black;
        color: red;
        border: 5px solid white;
        border-radius: 6px;
        float: right;
        position: relative;
        z-index: 99;
        margin-right: 5px;
      "
      @click="downloadTemplateMitra()"
    ></Button>

    <FileUpload
      mode="basic"
      name="mitra_file[]"
      :customUpload="true"
      @uploader="uploadTemplateMitra"
      class="my-2 float-right"
      chooseLabel="Upload Template Mitra"
      chooseIcon="pi pi-upload"
      style="
        background-color: black;
        color: red;
        border: 5px solid white;
        border-radius: 6px;
        float: right;
        position: relative;
        z-index: 99;
        margin-right: 5px;
      "
    />
  </div>
  <br />
  <div style="margin-top: 50px">
    <!-- datatable -->
    <DataTable
      :value="mitraData"
      :paginator="true"
      :rows="10"
      v-model:filters="filterPencarianMitra"
      filterDisplay="menu"
      :globalFilterFields="[
        'nama_mitra',
        'alamat_mitra',
        'nomor_handphone_mitra',
        'alamat_email_mitra',
        'nik_mitra',
      ]"
    >
      <template #header>
        <div style="text-align: left">
          <!-- <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilterPencarianMitra()"/> -->
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filterPencarianMitra['global'].value"
              placeholder="Cari . . ."
            />
          </span>
        </div>
      </template>
      <template #empty> Belum ada mitra atau locked </template>
      <template #loading> Loading data mitra ... </template>
      <Column
        field="nama_mitra"
        header="Nama"
        footer="Nama"
        :style="{ width: '200px' }"
        frozen
      ></Column>
      <Column
        field="nik_mitra"
        header="ID"
        footer="ID"
        :style="{ width: '100px' }"
      ></Column>
      <Column
        field="alamat_mitra"
        header="Alamat"
        footer="Alamat"
        :style="{ width: '200px' }"
      ></Column>
      <Column
        field="nomor_handphone_mitra"
        header="Handphone"
        footer="Handphone"
        :style="{ width: '200px' }"
      ></Column>
      <Column
        field="jenis_kelamin_mitra"
        header="Jenis Kelamin"
        footer="Jenis Kelamin"
        :style="{ width: '200px' }"
      ></Column>
      <Column
        field="alamat_email_mitra"
        header="Email"
        footer="Email"
        :style="{ width: '200px' }"
      ></Column>
      <Column
        field="pekerjaan_mitra"
        header="Pekerjaan Utama Mitra"
        footer="Pekerjaan Utama Mitra"
        :style="{ width: '300px' }"
      ></Column>
      <Column :style="{ width: '200px' }" bodyStyle="text-align: center">
        <template #header>
          <Button type="button" class="p-button-text" style="color: black"
            >Action</Button
          >
        </template>
        <template #body="slotProps">
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-warning"
            style="margin-right: 0.5em"
            @click="openMitraPemutakhiranDialog(slotProps.data)"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="konfirmasiHapusMitra(slotProps.data)"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </div>

  <!-- <Button type="button" label="Pencarian" icon="pi pi-search" style="margin:5px;background-color:black;color:red;border: 5px solid white;border-radius: 6px;"/> -->
  <ConfirmDialog style="color: black"></ConfirmDialog>

  <!-- dialog pendaftaran mitra -->
  <Dialog
    v-model:visible="mitraDialog"
    :style="{ width: '450px' }"
    :header="mitraBaru ? 'Pendaftaran Baru' : 'Pemutakhiran Data Mitra'"
    :modal="true"
    class="p-fluid"
  >
    <!-- <img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" :alt="product.image" class="product-image" v-if="product.image" /> -->
    <!-- <div class="field">
            <label for="name">Nama</label>
            <InputText id="name" v-model.trim="product.name" required="true" autofocus :class="{'p-invalid': submitted && !product.name}" />
            <small class="p-error" v-if="submitted && !product.name">Name is required.</small>
        </div> -->
    <div class="field">
      <label for="nik_mitra">NIK</label>
      <InputText id="nik_mitra" v-model="mitra.nik_mitra" required="true" />
    </div>
    <div class="field">
      <label for="nama_mitra">Nama</label>
      <InputText id="nama_mitra" v-model="mitra.nama_mitra" required="true" />
    </div>
    <div class="field">
      <label for="alamat_mitra">Alamat</label>
      <InputText
        id="alamat_mitra"
        v-model="mitra.alamat_mitra"
        required="true"
      />
    </div>
    <div class="field">
      <label for="pekerjaan_mitra">Pekerjaan Utama Mitra</label>
      <InputText
        id="pekerjaan_mitra"
        v-model="mitra.pekerjaan_mitra"
        required="true"
      />
    </div>
    <div class="field">
      <label for="dateformat">Tanggal Lahir</label>
      <Calendar
        id="dateformat"
        v-model="mitra.tanggal_lahir_mitra"
        dateFormat="yy/mm/dd"
      />
    </div>
    <div class="field">
      <label class="mb-3">Jenis Kelamin</label>
      <div class="formgrid grid">
        <div class="field-radiobutton col-6">
          <RadioButton
            id="category1"
            name="category"
            value="L"
            v-model="mitra.jenis_kelamin_mitra"
          />
          <label for="category1">Pria</label>
        </div>
        <div class="field-radiobutton col-6">
          <RadioButton
            id="category2"
            name="category"
            value="P"
            v-model="mitra.jenis_kelamin_mitra"
          />
          <label for="category2">Wanita</label>
        </div>
      </div>
    </div>
    <div class="field">
      <label for="nomor_handphone_mitra">Nomor Handphone</label>
      <InputText
        id="nomor_handphone_mitra"
        v-model="mitra.nomor_handphone_mitra"
        required="true"
      />
    </div>
    <div class="field">
      <label for="alamat_email_mitra">Email</label>
      <InputText
        id="alamat_email_mitra"
        v-model="mitra.alamat_email_mitra"
        required="true"
      />
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="mitraDialog = false"
        style="color: red"
      />
      <Button
        v-if="mitraBaru"
        label="Submit Mitra Baru"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitDataMitraBaru"
      />
      <Button
        v-else
        label="Submit Pemutakhiran Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitPemutahiranDataMitra"
      />
    </template>
  </Dialog>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import ConfirmDialog from "primevue/confirmdialog";
import RadioButton from "primevue/radiobutton";
import FileUpload from "primevue/fileupload";
import Calendar from "primevue/calendar";
import MitraService from "@/services/mitra.service";
import Toast from "primevue/toast";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    ConfirmDialog,
    InputText,
    RadioButton,
    FileUpload,
    MitraService,
    Toast,
    Calendar,
  },
  data() {
    return {
      mitraColumns: null,
      mitraData: null,
      mitraDialog: false,
      mitraBaru: true,
      mitra: {
        nik_mitra_lama: null,
        nama_mitra: null,
        alamat_mitra: null,
        alamat_email_mitra: null,
        nomor_handphone_mitra: null,
        nik_mitra: null,
        jenis_kelamin_mitra: null,
        tanggal_lahir_mitra: null,
        pekerjaan_mitra: null,
      },
      file_options: [
        {
          label: "Upload Template",
          icon: "pi pi-upload",
          command: () => {
            console.log("Upload clicked");
          },
        },
        {
          label: "Download Template",
          icon: "pi pi-download",
          command: () => {
            console.log("Download clicked");
          },
        },
      ],
      mitra_file: null,
      filterPencarianMitra: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_mitra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        alamat_mitra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nik_mitra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nomor_handphone_mitra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        alamat_email_mitra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
    };
  },
  async created() {},
  async mounted() {
    this.init();

    this.$toast.add({
      severity: "success",
      summary: "Sukses dung",
      detail: "Berhasil loading",
      life: 3000,
    });
    // setTimeout(() => {
    //   this.$toast.add({severity:'success', summary: 'Sukses dung', detail: "Berhasil loading", life: 3000});
    // },
    // 5000);
  },
  methods: {
    konfirmasiHapusMitra(data) {
      this.$confirm.require({
        message: "Apa yakin ingin menghapus data mitra ini?",
        header: "Konfirmas Penghapusan",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          //callback to execute when user confirms the action
          var response_temp = await MitraService.deleteMitraById(data.id_mitra);
          if (
            response_temp.status != null &&
            response_temp.status == "success"
          ) {
            await this.init();
            this.mitraDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus mitra",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi membuka dialog untuk pendaftaran mitra baru
    openMitraBaruDialog() {
      this.mitraBaru = true;
      this.mitra = {
        nama_mitra: null,
        alamat_mitra: null,
        alamat_email_mitra: null,
        nomor_handphone_mitra: null,
        nik_mitra: null,
        jenis_kelamin_mitra: null,
        tanggal_lahir_mitra: null,
        pekerjaan_mitra:null,
      };
      this.mitraDialog = true;
    },
    // fungsi membuka dialog untuk pemutakhiran data mitra
    openMitraPemutakhiranDialog(data) {
      this.mitra = data;
      this.mitra.nik_mitra_lama = data.nik_mitra;
      this.mitraBaru = false;
      this.mitraDialog = true;
    },
    // fungsi submit data mitra baru
    async submitDataMitraBaru() {
      var mitra_temp = this.mitra;
      var response_temp = await MitraService.submitMitraBaru(mitra_temp);
      if (response_temp.status != null && response_temp.status == "success") {
        await this.init();
        this.mitraDialog = false;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil menambahkan mitra",
          life: 5000,
        });
        this.mitra = {
          nama_mitra: null,
          alamat_mitra: null,
          alamat_email_mitra: null,
          nomor_handphone_mitra: null,
          nik_mitra: null,
          jenis_kelamin_mitra: null,
          tanggal_lahir_mitra: null,
          pekerjaan_mitra:null,
        };
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // fungsi submit pemutakhiran data mitra
    async submitPemutahiranDataMitra() {
      var mitra_temp = this.mitra;
      var response_temp = await MitraService.submitPemutakhiranMitra(
        mitra_temp
      );
      if (response_temp.status != null && response_temp.status == "success") {
        await this.init();
        this.mitraDialog = false;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan mitra",
          life: 5000,
        });
        this.mitra = {
          nama_mitra: null,
          alamat_mitra: null,
          alamat_email_mitra: null,
          nomor_handphone_mitra: null,
          nik_mitra: null,
          jenis_kelamin_mitra: null,
          tanggal_lahir_mitra: null,
          pekerjaan_mitra:null,
        };
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // fungsi upload template mitra
    async uploadTemplateMitra(event) {
      const formData = new FormData();
      formData.append("mitra_file", event.files[0]);
      var response_temp = await MitraService.submitUploadTemplateMitra(
        formData
      );
      if (response_temp.status != null && response_temp.status == "success") {
        await this.init();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil mengupload data mitra",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // fungsi upload template mitra
    async downloadTemplateMitra() {
      var response_temp = await MitraService.submitDownloadTemplateMitra();
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.ms-excel",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template_mitra_horassop.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    // fungsi untuk load data di tabel
    async init() {
      this.isloadingglobal = true;
      var response_temp = await MitraService.getMitra();
      this.mitraData = response_temp.data;
      setTimeout(() => {
        console.log(response_temp);
        this.isloadingglobal = false;
      }, 5000);
      this.isloadingglobal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  background: url("~@/assets/bg-login.png") no-repeat center center fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
}
</style>
