import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://horassop.gaweyan.com/api/kegiatan";
class KegiatanService {
  async getKegiatan() {
    return await axios
      .get(API_URL, { headers: authHeader() })
      .then((response) => {
        var data = response.data;
        data.data.forEach((element) => {
          element["tanggal_mulai_kegiatan"] = new Date(
            element["tanggal_mulai_kegiatan"]
          );
          element["tanggal_selesai_kegiatan"] = new Date(
            element["tanggal_selesai_kegiatan"]
          );
        });
        return data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }
  async getKegiatanById(id_kegiatan) {
    return await axios.get(API_URL + "/" + id_kegiatan, {
      headers: authHeader(),
    });
  }
  async getKegiatanBySearch(search) {
    return await axios.get(API_URL, {
      headers: authHeader(),
      search: search,
    });
  }
  async submitKegiatanBaru(kegiatan) {
    return await axios.post(
      API_URL,
      {
        nama_kegiatan: kegiatan.nama_kegiatan,
        penanggung_jawab_kegiatan: kegiatan.penanggung_jawab_kegiatan,
        satuan_kegiatan: kegiatan.satuan_kegiatan,
        tanggal_mulai_kegiatan: kegiatan.tanggal_mulai_kegiatan,
        tanggal_selesai_kegiatan: kegiatan.tanggal_selesai_kegiatan,
        beban_anggaran_kegiatan: kegiatan.beban_anggaran_kegiatan,
        klasifikasi_kegiatan: kegiatan.klasifikasi_kegiatan,
        created_by: kegiatan.created_by,
        updated_by: kegiatan.updated_by,
      },
      {
        headers: authHeader(),
      }
    );
  }
  async submitPemutakhiranKegiatan(kegiatan) {
    return await axios.put(
      API_URL + "/" + kegiatan.id_kegiatan,
      {
        // id_kegiatan : kegiatan.id_kegiatan,
        nama_kegiatan: kegiatan.nama_kegiatan,
        penanggung_jawab_kegiatan: kegiatan.penanggung_jawab_kegiatan,
        satuan_kegiatan: kegiatan.satuan_kegiatan,
        tanggal_mulai_kegiatan: kegiatan.tanggal_mulai_kegiatan,
        tanggal_selesai_kegiatan: kegiatan.tanggal_selesai_kegiatan,
        beban_anggaran_kegiatan: kegiatan.beban_anggaran_kegiatan,
        klasifikasi_kegiatan: kegiatan.klasifikasi_kegiatan,
        created_by: kegiatan.created_by,
        updated_by: kegiatan.updated_by,
      },
      {
        headers: authHeader(),
      }
    );
  }
  async deleteKegiatanById(id_kegiatan) {
    return await axios
      .delete(API_URL + "/" + id_kegiatan, {
        headers: authHeader(),
      })
      .then((response) => {
        var data = response.data;
        return data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }
}
export default new KegiatanService();
