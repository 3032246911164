<template>
  <div style="margin: 25px">
    <h1
      class="my-2"
      style="
        color: white;
        font-weight: bold;
        float: center;
        text-align: center;
        -webkit-text-stroke: 1px black;
      "
    >
      HALAMAN TIDAK ADA
    </h1>
    <p></p>
    <iframe
      src="https://giphy.com/embed/26Ff1Bjf49muK4LhC"
      width="480"
      height="270"
      frameBorder="0"
      class="giphy-embed"
      allowFullScreen
    ></iframe>
    <p>
      <a
        href="https://giphy.com/gifs/indonesia-saung-budaya-saungbudaya-26Ff1Bjf49muK4LhC"
        >via GIPHY</a
      >
    </p>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
