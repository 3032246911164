import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://horassop.gaweyan.com/api/pekerjaan-mitra";
class PekerjaanMitraService {
  async getPekerjaanMitra() {
    return await axios
      .get(API_URL, { headers: authHeader() })
      .then((response) => {
        var data = response.data;
        return data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }
  async getPekerjaanMitraRekap() {
    return await axios
      .get(API_URL + "-rekap", { headers: authHeader() })
      .then((response) => {
        var data = response.data;
        return data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }
  async getPekerjaanMitraRekapTahunBulan(tahun, bulan) {
    return await axios
      .get(API_URL + "-rekap/" + tahun + "/" + bulan, { headers: authHeader() })
      .then((response) => {
        var data = response.data;
        return data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }
  async getPekerjaanMitraByIdKegiatan(id_kegiatan) {
    return await axios
      .get(API_URL + "/" + id_kegiatan, {
        headers: authHeader(),
      })
      .then((response) => {
        var data = response.data;
        data.data.forEach((element) => {
          element["tanggal_mulai_pekerjaan"] = new Date(
            element["tanggal_mulai_pekerjaan"]
          );
          element["tanggal_selesai_pekerjaan"] = new Date(
            element["tanggal_selesai_pekerjaan"]
          );
        });
        return data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }
  //   async getPekerjaanMitraBySearch(search) {
  //     return await axios.get(API_URL, {
  //         headers: authHeader(),
  //         search : search,
  //     });
  //   }
  async submitPekerjaanMitraBaru(mitra, kegiatan) {
    return await axios
      .post(
        API_URL,
        {
          id_kegiatan: kegiatan.id_kegiatan,
          id_mitra: mitra.id_mitra,
          biaya_per_satuan_pekerjaan: 0,
          jumlah_pekerjaan: 0,
          biaya_transportasi_pekerjaan: 0,
          biaya_harian_pekerjaan: 0,
          biaya_penginapan_pekerjaan: 0,
          satuan_pekerjaan: kegiatan.satuan_kegiatan,
          tanggal_mulai_pekerjaan: kegiatan.tanggal_mulai_kegiatan,
          tanggal_selesai_pekerjaan: kegiatan.tanggal_selesai_kegiatan,
          beban_anggaran_pekerjaan: kegiatan.beban_anggaran_kegiatan,
          klasifikasi_pekerjaan: kegiatan.klasifikasi_kegiatan,
          posisi_pekerjaan: "Pencacah",
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        var data = response.data;
        data.data["tanggal_mulai_pekerjaan"] = new Date(
          data.data["tanggal_mulai_pekerjaan"]
        );
        data.data["tanggal_selesai_pekerjaan"] = new Date(
          data.data["tanggal_selesai_pekerjaan"]
        );
        return data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }
  async submitPemutakhiranPekerjaanMitra(pekerjaan_mitra) {
    return await axios
      .put(
        API_URL + "/" + pekerjaan_mitra.id_pekerjaan,
        {
          id_pekerjaan: pekerjaan_mitra.id_pekerjaan,
          id_kegiatan: pekerjaan_mitra.id_kegiatan,
          id_mitra: pekerjaan_mitra.id_mitra,
          biaya_per_satuan_pekerjaan:
            pekerjaan_mitra.biaya_per_satuan_pekerjaan,
          jumlah_pekerjaan: pekerjaan_mitra.jumlah_pekerjaan,
          biaya_transportasi_pekerjaan:
            pekerjaan_mitra.biaya_transportasi_pekerjaan,
          biaya_harian_pekerjaan: pekerjaan_mitra.biaya_harian_pekerjaan,
          biaya_penginapan_pekerjaan:
            pekerjaan_mitra.biaya_penginapan_pekerjaan,
          satuan_pekerjaan: pekerjaan_mitra.satuan_pekerjaan,
          tanggal_mulai_pekerjaan: pekerjaan_mitra.tanggal_mulai_pekerjaan,
          tanggal_selesai_pekerjaan: pekerjaan_mitra.tanggal_selesai_pekerjaan,
          beban_anggaran_pekerjaan: pekerjaan_mitra.beban_anggaran_pekerjaan,
          klasifikasi_pekerjaan: pekerjaan_mitra.klasifikasi_pekerjaan,
          posisi_pekerjaan: pekerjaan_mitra.posisi_pekerjaan,
          created_by: pekerjaan_mitra.created_by,
          updated_by: pekerjaan_mitra.updated_by,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        var data = response.data;
        return data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async pengajuanSPKMitra(pekerjaan_mitra) {
    console.log("test");
    return await axios
      .put(
        API_URL + "-pengajuan-spk/" + pekerjaan_mitra.id_pekerjaan,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        var data = response.data;
        return data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async pembatalanSPKMitra(pekerjaan_mitra) {
    return await axios
      .put(API_URL + "-pembatalan-spk/" + pekerjaan_mitra.id_pekerjaan,{}, {
        headers: authHeader(),
      })
      .then((response) => {
        var data = response.data;
        return data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async daftarKegiatanMitra(id_mitra, tahun, bulan) {
    return await axios
      .get(API_URL + "-daftar-kegiatan/" + id_mitra + "/" + tahun + "/" + bulan, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async downloadSPKMitra(id_mitra, tahun, bulan) {
    return await axios
      .get(API_URL + "-spk/" + id_mitra + "/" + tahun + "/" + bulan, {
        headers: authHeader(),
        responseType: "blob",
      })
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }

  async deletePekerjaanMitraById(id_kegiatan) {
    return await axios
      .delete(API_URL + "/" + id_kegiatan, {
        headers: authHeader(),
      })
      .then((response) => {
        var data = response.data;
        return data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async deletePekerjaanMitraByIds(id_kegiatan, selected_ids) {
    // console.log(selected_ids);
    return await axios
      .post(
        API_URL + "-deletes",
        {
          id_kegiatan: id_kegiatan,
          selected_ids: JSON.stringify(selected_ids),
        },
        {
          headers: authHeader(),
          // 'Content-Type': 'application/x-www-form-urlencoded',
          // 'Access-Control-Allow-Headers': '*',
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
}
export default new PekerjaanMitraService();
