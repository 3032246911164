import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://horassop.gaweyan.com/api/mitra";
class MitraService {
  async getMitra() {
    return await axios
      .get(API_URL, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async getMitraById(id_mitra) {
    return await axios
      .get(API_URL + "/" + id_mitra, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async getMitraBebasByIdKegiatan(id_kegiatan) {
    return await axios
      .get(API_URL + "-bebas/" + id_kegiatan, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async getMitraBySearch(search) {
    return await axios
      .get(
        API_URL,
        { search: search },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async submitMitraBaru(mitra) {
    return await axios
      .post(
        API_URL,
        {
          nama_mitra: mitra.nama_mitra,
          alamat_mitra: mitra.alamat_mitra,
          nik_mitra: mitra.nik_mitra,
          tanggal_lahir_mitra: mitra.tanggal_lahir_mitra,
          jenis_kelamin_mitra: mitra.jenis_kelamin_mitra,
          nomor_handphone_mitra: mitra.nomor_handphone_mitra,
          alamat_email_mitra: mitra.alamat_email_mitra,
          pekerjaan_mitra: mitra.pekerjaan_mitra,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error.toJSON();
      });
  }

  async submitPemutakhiranMitra(mitra) {
    return await axios
      .put(API_URL + "/" + mitra.id_mitra, {
        id_mitra: mitra.id_mitra,
        nama_mitra: mitra.nama_mitra,
        alamat_mitra: mitra.alamat_mitra,
        nik_mitra: mitra.nik_mitra,
        // nik_mitra_lama : mitra.nik_mitra_lama,
        tanggal_lahir_mitra: mitra.tanggal_lahir_mitra,
        jenis_kelamin_mitra: mitra.jenis_kelamin_mitra,
        nomor_handphone_mitra: mitra.nomor_handphone_mitra,
        alamat_email_mitra: mitra.alamat_email_mitra,
        pekerjaan_mitra: mitra.pekerjaan_mitra,
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async submitUploadTemplateMitra(formData) {
    return await axios
      .post(API_URL + "-template", formData, {
        headers: authHeader(),
        "Content-Type": "multipart/form-data",
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async submitDownloadTemplateMitra() {
    return await axios
      .get(API_URL + "-template", {
        headers: authHeader(),
        responseType: "blob",
      })
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }

  async deleteMitraById(id_mitra) {
    return await axios
      .delete(API_URL + "/" + id_mitra, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
}
export default new MitraService();
