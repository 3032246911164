<template>
  <div style="margin: 20px">
    <h2
      class="my-2"
      style="
        color: white;
        font-weight: bold;
        float: left;
        text-align: left;
        -webkit-text-stroke: 1px black;"
    >
      Dokumen Perjanjian Kerja
    </h2>
  </div>

  <!-- start dialog untuk untuk daftar kegiatan dari seorang mitra pada bulan dan tahun tertentu (SPK) -->
  <Dialog
    v-model:visible="displayKegiatanDialog"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '80vw' }"
    :modal="true"
  >
    <template #header>
      <h3>Kegiatan oleh {{ kegiatanDialog.nama_mitra }} (Bulan {{ kegiatanDialog.bulan }} Tahun {{ kegiatanDialog.tahun }})</h3>
      <div class="flex justify-content-between">
        <!-- <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilterPencarianMitra()"/> -->
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            v-model="filterKegiatanDialog['global'].value"
            placeholder="Cari nama, penanggung jawab kegiatan"
          />
        </span>
      </div>
    </template>
    <template #empty> Belum ada mitra dengan spesifikasi tersebut </template>
    <template #loading> Pencarian data mitra . . . </template>
    <DataTable
      :value="kegiatanDialog.kegiatans"
      v-model:filters="filterKegiatanDialog"
      dataKey="nik_mitra"
      filterDisplay="menu"
      :globalFilterFields="['nama_kegiatan', 'penanggung_jawab_kegiatan']"
      :paginator="true"
      :rows="10"
    >
      <Column
        field="nama_kegiatan"
        header="Nama Kegiatan"
        footer="Nama Kegiatan"
        :style="{ width: '200px' }"
        frozen
      ></Column>
      <Column
        field="penanggung_jawab_kegiatan"
        header="Penanggung Jawab Kegiatan"
        footer="Penanggung Jawab Kegiatan"
        :style="{ width: '100px' }"
      ></Column>
      <Column
        field="tanggal_mulai_pekerjaan"
        header="Tanggal Mulai Pekerjaan"
        footer="Tanggal Mulai Pekerjaan"
        :style="{ width: '200px' }"
      ></Column>
      <Column
        field="tanggal_selesai_pekerjaan"
        header="Tanggal Selesai Pekerjaan"
        footer="Tanggal Selesai Pekerjaan"
        :style="{ width: '200px' }"
      ></Column>
      <Column :style="{ width: '200px' }" bodyStyle="text-align: center">
        <template #header>
          <Button type="button" class="p-button-text" style="color: black"
            >Action</Button
          >
        </template>
        <template #body="slotProps">
          <Button
            type="button"
            icon="pi pi-danger"
            label="Batalkan SPK"
            class="p-button-danger"
            style="margin-right: 0.5em"
            @click="sentPembatalanSPK(slotProps.data)"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </Dialog>
  <!-- end -->


  <!-- <Button type="button" label="Pencarian" icon="pi pi-search" style="margin:5px;background-color:black;color:red;border: 5px solid white;border-radius: 6px;"/> -->
  <ConfirmDialog style="color: black"></ConfirmDialog>
  <!-- datatable -->
  <div style="margin-top: 20px">
    <DataTable
      :value="pekerjaanMitraData"
      :paginator="true"
      :rows="10"
      v-model:filters="filterPencarianPekerjaanMitra"
      filterDisplay="menu"
      :globalFilterFields="['nama_mitra', 'daftar_kegiatan', 'tahun']"
    >
      <template #header>
        <div style="text-align: left">
          <!-- <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilterPencarianMitra()"/> -->
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filterPencarianPekerjaanMitra['global'].value"
              placeholder="Cari . . ."
            />
          </span>
        </div>
      </template>
      <template #empty> Belum ada pekerjaan mitra atau locked </template>
      <template #loading> Loading data pekerjaan mitra ... </template>
      <Column
        field="nama_mitra"
        header="Nama Mitra"
        footer="Nama Mitra"
        :style="{ width: '200px' }"
        frozen
      ></Column>
      <Column
        field="tahun"
        header="Tahun Kegiatan"
        footer="Tahun Kegiatan"
        :style="{ width: '100px' }"
      ></Column>
      <Column
        field="bulan"
        header="Bulan Kegiatan"
        footer="Bulan Kegiatan"
        :style="{ width: '200px' }"
      ></Column>
      <Column
        field="bayaran_pekerjaan"
        header="Bayaran Total Kegiatan (SPK)"
        footer="Bayaran Total Kegiatan (SPK)"
        :style="{ width: '200px' }"
      ></Column>
      <Column
        field="jumlah"
        header="Jumlah Kegiatan (SPK)"
        footer="Jumlah Kegiatan (SPK)"
        :style="{ width: '200px' }"
      ></Column>
      <Column
        field="daftar_kegiatan"
        header="Daftar Kegiatan (SPK)"
        footer="Daftar Kegiatan (SPK)"
        :style="{ width: '200px' }"
      ></Column>
      <Column :style="{ width: '300px' }" bodyStyle="text-align: center">
        <template #header>
          <Button type="button" class="p-button-text" style="color: black"
            >Action</Button
          >
        </template>
        <template #body="slotProps">
            <Button
              id="downloadSPKButton"
              type="button"
              icon="pi pi-pencil"
              class="p-button-info"
              label="Cetak SPK"
              style="margin: 5px"
              @click="downloadSPKMitra(slotProps.data)"
            ></Button>
            <Button
              v-if="showDetailSPK"
              id="hapusSPKButton"
              type="button"
              icon="pi pi-trash"
              class="p-button-secondary"
              label="Detail Kegiatan"
              style="margin: 5px"
              @click="openKegiatanDialog(slotProps.data)"
            ></Button>
          <!-- <div v-else>Pengajuan belum dikirimkan</div> -->
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import ConfirmDialog from "primevue/confirmdialog";
import RadioButton from "primevue/radiobutton";
import FileUpload from "primevue/fileupload";

import Toast from "primevue/toast";
import MitraService from "@/services/mitra.service";
import UserService from "@/services/user.service";
import PekerjaanMitraService from "@/services/pekerjaan-mitra.service";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    ConfirmDialog,
    InputText,
    RadioButton,
    FileUpload,
    MitraService,
    PekerjaanMitraService,
    Toast,
    UserService,
  },
  data() {
    return {
      mitraColumns: null,
      pekerjaanMitraData: null,
      mitraDialog: false,
      mitraBaru: true,
      pekerjaan_mitra: {
        id_pekerjaan: null,
        id_mitra: null,
        nama_mitra: null,
        id_kegiatan: null,
        nama_kegiatan: null,
        jumlah_pekerjaan: null,
        satuan_pekerjaan: null,
        biaya_per_satuan_pekerjaan: null,
        biaya_transportasi_pekerjaan: null,
        biaya_penginapan_pekerjaan: null,
        biaya_harian_pekerjaan: null,
        keterangan_pekerjaan: null,
        tanggal_mulai_pekerjaan: Date.now(),
        tanggal_selesai_pekerjaan: Date.now(),
      },
      mitra_file: null,
      filterPencarianPekerjaanMitra: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_mitra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        daftar_kegiatan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        tahun_kegiatan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      // variabel dialog kegiatan
      displayKegiatanDialog: false,
      filterKegiatanDialog: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_kegiatan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        penanggung_jawab_kegiatan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        id_kegiatan: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      kegiatanDialog: {
        nama_mitra: null,
        bulan: null,
        tahun: null,
        kegiatans: [],
      },
      showDetailSPK: false,
      profil: null,
    };
  },
  async created() {},
  async mounted() {
    this.init();
  },
  methods: {
    konfirmasiHapusMitra(data) {
      this.$confirm.require({
        message: "Apa yakin ingin menghapus data mitra ini?",
        header: "Konfirmas Penghapusan",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          //callback to execute when user confirms the action
          var response_temp = await MitraService.deleteMitraById(data.id_mitra);
          if (
            response_temp.status != null &&
            response_temp.status == "success"
          ) {
            await this.init();
            this.mitraDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus mitra",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi untuk load data di tabel
    async init() {
      this.isloadingglobal = true;
      var profil_temp = await UserService.getUserDetail();
      this.profil = profil_temp.data;
      if (this.profil.role == "Super Admin") {
        this.showDetailSPK = true;
      }
      var response_data = await PekerjaanMitraService.getPekerjaanMitraRekap();
      if (response_data.status != null && response_data.status == "success") {
        this.pekerjaanMitraData = response_data.data;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil loading data",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 3000,
        });
      }
      this.isloadingglobal = false;
    },
    // fungsi untuk download SPK Mitra
    async downloadSPKMitra(data) {
      // console.log(data);
      var response_temp = await PekerjaanMitraService.downloadSPKMitra(
        data.id_mitra,
        data.tahun,
        data.bulan
      );
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "SPK Mitra " + data.tahun +" "+ data.bulan + " " + data.nama_mitra + ".docx"
      );
      document.body.appendChild(link);
      link.click();
    },
    async openKegiatanDialog(data) {
      var response_data = await PekerjaanMitraService.daftarKegiatanMitra(data.id_mitra, data.tahun, data.bulan);
      if (response_data.status != null && response_data.status == "success") {
        this.kegiatanDialog = {
          nama_mitra : data.nama_mitra,
          bulan : data.bulan,
          tahun : data.tahun, 
          kegiatans : response_data.data,
        };
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil mengambil daftar kegiatan mitra pada bulan dan tahun tertentu",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.displayKegiatanDialog = true;
    }
    ,
    async sentPembatalanSPK(pekerjaan_mitra) {
      this.$confirm.require({
        message: "Apa yakin ingin menghapus SPK untuk mitra pada kegiatan ?" ,
        header: "Konfirmasi Penghapusan SPK",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus SPK",
        rejectLabel: "Cancel",
        acceptClass: "p-button-danger",
        accept: async () => {
          //callback to execute when user confirms the action
          var response_data = await PekerjaanMitraService.pembatalanSPKMitra(pekerjaan_mitra);
          if (response_data.status != null && response_data.status == "success") {
            await this.init();
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil membatalkan SPK pekerjaan mitra",
              life: 5000,
            });
            this.displayKegiatanDialog = false
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_data,
              life: 7000,
            });
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
      
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  background: url("~@/assets/bg-login.png") no-repeat center center fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
}
</style>
